.empty-voucher{
    height: 300px;
    background-color: var(--bs-border-color);
    margin-bottom: 50px;
    border-radius: 20px;
    padding: 40px;
}
.empty-voucher h2{
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 1px;
    color: red;
    font-family: monospace;
}
.arrow-heading {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 25px;
}
.arrow-heading svg{
    cursor: pointer;
}
.voucher-name,.arrow-heading svg {
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase;
}

.category-img {
    width: 85%;
    height: 350px;
    margin: auto;
}

.voucher-span {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
}

.voucher-rating,
.voucher-download,
.voucher-date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.voucher-download img,
.voucher-date img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}
.voucher-box {
    /* background-image: url('../../assets/voucher_game/box_back.png'); */
    height: 125px;
    width: 100%;
    text-align: center;
    padding: 10px 5px;
    background:linear-gradient(180deg, #0ABEB9 -58.83%, #3A144E 135.04%);
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-wrap: pretty;
    border-radius: 20px;
}

.voucher-box p {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.7;
    text-align: center;
    margin-bottom: 0;
}

.arrow {
    cursor: pointer;
}

.voucher-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.voucher-list span {
    display: inline-block;
    color: rgba(0, 0, 0, 0.51);
    font-size: 19px;
    font-weight: bold;
}
.voucher-list span .token_icon, .price .token_icon{
    display: inline;
    width: 20px;
    margin :0 5px ;

}
.voucher .price {
    color: black;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
}

.list-title {
    width: 150px;
}

.voucher-details {
    font-size: 20px !important;
    font-weight: 500;
    color: black !important;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.voucher-qty {
    width: 70px;
    height: 35px;
    border-radius: 10px;
    outline: none;
    border: none;
    background: rgba(255, 212, 0, 1);
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
}

.voucher-video img {
   width: 95%;
   margin: auto;
   height: auto;
}

.voucher-btn {
    width: 100%;
    height: 60px;
    border: none;
    outline: none;
    background: rgba(58, 20, 78, 1);
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 5px;
}

.add-cart svg , .add-wishlist svg{
    width: 60px;
    height: 60px;
    cursor: pointer;
}
.add-wishlist svg:hover{
    color: red;
    transition: ease-in-out all 0.4s;
}
.add-cart svg:hover{
    color: #F9C05F;
    transition: ease-in-out all 0.4s;
}

/* payment modal */
.voucher-modal{
    /* height: 450px !important; */
    overflow-y: scroll;
    border-radius: 27px;
    /* background-color: rgba(0, 0, 0, 0.6) !important; */
    /* background-image: none; */
}
.modal .card{
    border: none;
    background-color: transparent;
}
.modal-body  .card .price{
    text-align: left !important;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    padding: 5px 0px;
}
.modal-body .card .qty p{
    text-align: right !important;
    margin-bottom: 0;
}
.modal-body .card .qty p span{
    margin: 0px 5px;
    font-size: 18px;
}
.voucher-video{
    margin: 0 50px;
}
/* .buy-modal .modal-content{
    background-image: none;
    background-color: #fcddbb;
    border-radius: 0;
} */
/* .buy-modal .modal-content .voucher-modal{
    border-radius: 0;
} */
.slick-slider{
    padding: 0 !important;
}
.confirm-modal .confirm-btn{
    display: flex;
    align-items: center;
    gap: 5px;
   

    font-weight: bold;
    background: rgba(58, 20, 78, 1);
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    float: right;
    border-radius: 10px;

}
.confirm-modal .cancel-btn{
    display: flex;
    align-items: center;
    gap: 5px;
   

    font-weight: bold;
    background-color: rgba(255, 195, 18, 1);
    width: -moz-fit-content;
    width: fit-content;
    float: right;
    border-radius: 10px;
   color :rgb(4, 1, 20);
}
/* mobile responsive */
@media (max-width: 525px) {
    .react-tabs__tab:nth-child(3){
        display: none;
    }
    .voucher{
        padding-top: 100px !important;
    }
    .voucher-video{
        margin: 0;
    }
    .empty-voucher{
        padding: 40px 20px;
        height: auto;
    }
    .empty-voucher h2{
        letter-spacing: 1px;
        font-size: 17px;
        line-height: 1.7;
        margin-bottom: 0;
    }
    .voucher-list img {
        width: 150px;
    }
    .category-img{
        width: 100%;
        height: 250px;
        margin-bottom: 20px !important;
    }
    .voucher-list .price {
        font-size: 15px;
    }
    .voucher .slick-list {
        width: 100%;
        margin: auto;
    }

    .voucher .slick-slider {
        width: 100%;
    }
    .voucher-video img{
        width: 100%;
        margin: 0px !important;
        padding: 5px;

    }
    .voucher-name{
        font-size: 25px;
    }
    .voucher-rating, .voucher-download, .voucher-date{
        margin-bottom: 20px;
    }
}