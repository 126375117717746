.searchBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:15px;
    gap: 10px;
    background: #2f3640;
    border-radius: 50px;
    position: relative;
    margin-bottom: 25px;
  }
  
  .searchInput {
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    width: 90%;
  }
  .searchInput::placeholder{
    color: white;
    background-color: transparent;
  }