.android_games{
    padding: 50px 0;
    background-color: #FAF5E7;
}
.android_games .swipper-slider{
    height: 100%;
}
.android_games .game-item img{
    border-radius: 5px;
    width: 100%;
    height: 250px;
    margin-bottom: 15px;
}
.game-item .btn-join{
    width: 100%;
    font-size: 20px;
    height: 55px;
    border-radius: 0;
    cursor: pointer;
}
/* .battles-games {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
} */
.battles-games .android_game_item{
    width: 18%;
}
@media (max-width:525px) {
    .android_games .game-item img{
        width: 100%;
        height: 175px;
    }
    .game-item .btn-join{
        font-size: 15px;
    }
}