.game-heading {
    text-align: start;
}

.game-heading h2 {
    font-size: 30px;
}

.game-heading .game-level {
    font-size: 19px;
    color: rgba(0, 158, 221, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.game-details span {
    display: block;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.game-details .gameDetails-img {
    height: 300px;
    border-radius: 20px;
}

.game-details .game-date {
    color: rgba(0, 158, 221, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.game-info p {
    line-height: 2;
    font-size: 17px;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: start;
    color: rgba(133, 133, 133, 1);
    font-weight: bold;
}

.game-plus-info {
    padding: 0;
    color: rgba(133, 133, 133, 1);
    font-size: 17px !important;
    padding: 0 !important;
}

.game-btns {
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.game-btns button {
    width: 100%;
    height: 64px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: bold;
    background-color: transparent;
}

.game-btns button,
.game-btn .back-btn {
    background-color: black;
    color: white;
}

.game-details a.back-btn {
    background-color: black;
    color: white;
    display: block;
    text-decoration: none;
    padding: 20px 0;
    text-align: center;
}

.game-btns img {
    cursor: pointer;
}

.game-desc ul li,
h2 {
    color: black;
}

.game-desc h2 {
    font-size: 23px;

}

.game-desc ul li {
    /* list-style: auto; */
    font-size: 20px;
    font-weight: 400;
}

@media (max-width:525px) {
    .game-heading h2 {
        text-align: left;
        font-size: 20px;
        margin-top: 10px;
    }

    .game-desc h2,
    .game-details span {
        font-size: 14px;
    }

    .game-heading .game-level {
        text-align: left;
    }

    .game-details a.back-btn{
        margin-bottom: 15px;
        font-size: 25px;
    }
    .star{
        width: 100%;
    }
}