.hero{
    background-image: url('../../assets/gamer_club_back.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    margin-top: 100px;
}
.hero-section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 50px;
    height: 100%;
    /* background: linear-gradient(88deg, #000 0.29%, rgba(0, 0, 0, 0.97) 53.23%, rgba(0, 0, 0, 0.98) 55.13%, rgba(0, 0, 0, 0.94) 55.56%, rgba(0, 0, 0, 0.98) 55.75%, rgba(0, 0, 0, 0.87) 64.78%, rgba(0, 0, 0, 0.00) 96.09%); */
}
.hero-section .logo{
    width: 300px;
    margin-bottom: 35px;
}
.hero-section h2{
    font-size: 65px;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: bold;
}
h2 span{
    display: block;
    color: rgba(255, 195, 18, 1);
    letter-spacing: 2px;
    margin-bottom: 25px;

}
.playit-links{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.playit-links img{
    width: 250px;
    border-radius: 15px;
}
.hero-section p{
    width: 65%;
    text-align: left;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    margin: 15px 0;
}
.btn-join{
    width: auto;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: rgba(255, 195, 18, 1);
    padding: 10px 20px;
    justify-content: center;
    border-radius: 10px;
    outline: none;
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: 'Oswald', sans-serif;
}
.btn-join:hover{
    background-color: #dd7d02;
    color: white;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
}
.btn-join img{
    margin: 0 5px;
    width: 30px;
}
/* mobile responsie */
@media (max-width: 525px) {
    .hero{
        background-image: url("../../assets/playit_web_phone.png");
        height: 650px;
        background-position: center;
        background-size: cover;
    }
    .hero-section{
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
        position: absolute;
        width: 100%;
        padding-bottom: 105px;
        padding-left: 0;
        background: none;
    }
    .hero-section .logo{
        width: 200px;
        margin-bottom: 10px;
    }
    .hero-section h2{
        font-size: 35px;
    }
    .hero-section h2 span{
        margin-bottom: 10px;
    }
    .hero-section p{
        width: 100%;
        font-size: 13px;
        line-height: 1.7;
    }
    .hero-section .playit-links img{
        width: 120px;
    }
    .btn-join{
        font-size: 18px;
        line-height: 35px;
        height: auto;
        padding: 10px;
    }
}