.tourn-cards {
    margin: 50px 0;
    display: none;
}

.tourn {
    background: linear-gradient(180deg, #0ABEB9 -58.84%, #3A144E 100%);
    border-radius: 10px;
    position: relative;
    padding: 0 10px 10px 10px;
    height: auto;
    margin-bottom: 20px;
    /* height: 190px; */
}

.tourn-img img {
    width: 100%;
    /* margin-top: -40px; */
}

.tourn-prize {
    background-image: url('../../assets/myTournaments/background_btn.png');
}

.tourn-prize {
    height: 95px;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
}

.tourn-prize img {
    margin: 0px 5px;
    width: 25px;
    height: 25px;
}

.tourn-name {
    height: 100%;
    display: flex;
    align-items: unset;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    padding: 25px 0 10px 0;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px;
}

.tourn-name p {
    margin: 0;
    color: white;
}

.tourn-createdBy {
    background-color: rgba(255, 200, 0, 1);
    padding: 5px 0px;
    border-radius: 10px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 16px;
    text-align: center;
}

.tourn-rank {
    position: absolute;
    left: 35%;
    top: -15px;
    color: rgba(249, 181, 25, 1);
    background-color: rgba(81, 33, 33, 1);
    border-radius: 10px;
    padding: 5px 10px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: bold;
}

.cards-member {
    display: none;
}

@media (max-width:525px) {
    .tourn-name{
        font-size: 15px;
        text-align: center;
    }
    .tourn-prize{
        margin-bottom: 0;
        padding-top: 20px;
        font-size: 9px;
        background-size: contain;
        background-position: center;
    }
}