/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500;600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap"); */
body {
  margin: 0;
  font-family: sans-serif !important;
  text-transform: capitalize;
}
li {
  list-style: none;
}
/* font-family: "Rajdhani", sans-serif; --- background: rgba(58, 20, 78, 1);
*/
.btn-submit {
  border-radius: 5px;
  width: 100%;
  background: rgba(58, 20, 78, 1);
  color: white;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}
.language-selector {
  background-color: transparent !important;
  margin-bottom: 0;
  color: #fff;
  height: auto;
  border: none;
  outline: none;
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;
}
.language-selector option {
  background: rgba(58, 20, 78, 1);
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.categories-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 25px;
  overflow-x: scroll;
  scrollbar-width: none;
  text-wrap: nowrap;
}
