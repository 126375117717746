@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
.wishList{
    margin: auto;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 20px;
}
.list-content{
    background-color: white;
    border-radius: 20px;
    text-align: start;
    padding: 20px;
    min-height: 50vh;

}
.list-title{
    width: 100%;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-family: 'Inter', sans-serif;
}
.game-infos{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.wishList .qty-inp{
    width: fit-content !important;
}
.wishList .game-infos p, .game-price{
    margin: 0;
    font-size: 18px;
    font-weight: bold !important;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
}
.add .btn-add{
    background-color: #000;
    border-radius: 5px;
    color: white;
    text-transform: capitalize;
    padding: 10px 15px;
    font-family: 'Inter', sans-serif;
}
.add img{
    cursor: pointer;
}
.wishList .btn-back{
    margin-bottom: 35px;
    width: 350px;
    padding: 7px 0px;
    background-color: black;
    color: white;
    font-size: 25px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: bold;
    border-radius: 5px;
}
@media (max-width:525px) {
    .shopping .game-infos {
        align-items: center;
    }
    .shopping{
        padding: 10px;
    }
    .list-content{
        padding: 12px;
    }
}