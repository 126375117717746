.payment-content {
  margin: auto;
  border-radius: 20px;
  margin-bottom: 50px;
}
.credit svg {
  cursor: pointer !important;
}
.shopping-check {
  text-decoration: none !important;
}
.payment-content .total .item {
  cursor: pointer;
}

.credit-img {
  display: none;
}

.show-cards {
  display: block;
}

.credit-games {
  background: linear-gradient(180deg, #0abeb9 -58.84%, #3a144e 100%) !important;
  border-radius: 15px;
  margin-top: 35px;
}

.game {
  padding: 30px 20px;
}

.payment-content .game-info {
  display: flex;
  align-items: flex-start;
  color: white;
  text-align: left;
  width: 100%;
  font-weight: bold;
}

.game-info img {
  width: 73px;
  height: 61px;
  border-radius: 10px;
}

.game-title {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  line-height: 1.8;
  font-size: 15px;
}

.payment-content .price {
  margin-bottom: 0;
  color: white;
  font-size: 15px;
}

.payment-content .store-tabs {
  background-color: rgba(255, 200, 0, 1) !important;
  margin-top: 40px;
}

.payment-content .final-price {
  color: red;
}

.payment-content .qty {
  display: flex;
  align-items: baseline;
  color: white;
  font-size: 23px;
  justify-content: flex-end;
  gap: 5px;
}

.payment-content .qty p {
  color: white;
  font-weight: bold;
  margin: 0;
}

.payment-content .qty img {
  cursor: pointer;
}

.qty p button {
  width: 22px;
  height: 100%;
  border-radius: 5px;
  line-height: 30px;
  padding: 0;
}

.total {
  margin-top: 15px;
  font-weight: bold;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.item p,
.total-price {
  margin: 0;
}

.payment-content .total-price {
  margin-bottom: 15px;
  text-align: end;
}

.payment-content .empty-cart {
  padding: 25px 0 25px 20px;
  line-height: 1.8;
}

span.price {
  font-size: 25px;
  color: #000;
}

.credit-inputs {
  margin: 10px 0;
  background-color: white;
  padding: 15px 10px !important;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold;
  text-align: start !important;
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
}

.card-num label {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}

.credit-inputs input {
  width: 23%;
  border: none;
  border-right: 1px solid #000;
  text-align: center;
  outline: none;
  color: rgba(0, 0, 0, 0.14);
}

.credit-inputs input:last-child {
  border-right: none;
}

.card-info {
  margin: 20px 10px;
}

.cvv {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cvv input {
  padding: 15px 20px;
  width: 100%;
  margin: 10px 0;
  border-radius: 0px;
  border: 1px solid #eee;
  text-align: center;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.14);
}

.expire-inputs input {
  width: 100%;
  margin: 10px 0;
  padding: 15px 10px;
  border: 1px solid #ddd;
  text-align: center;
  outline: none;
  color: rgba(0, 0, 0, 0.14);
}

.expire-inputs input::placeholder,
.cvv input::placeholder {
  font-weight: bold;
}

.credit-img .qr-text {
  width: 100%;
  margin: auto;
  font-size: 19px;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1.6;
}
.span-pointer {
  cursor: pointer;
}
@media (max-width: 768px) {
  .payment-content .total-price {
    text-align: end !important;
  }
  .payment-content-reverse {
    flex-direction: column-reverse;
  }
}
@media (max-width: 525px) {
  .payment-content .credit-games {
    margin-top: 0;
  }

  .payment-content .game-title {
    line-height: 1.5;
    font-size: 15px;
  }

  .payment-content .price {
    margin: 10px 5px;
    padding: 0;
  }

  .payment-content-reverse {
    flex-direction: column-reverse;
  }

  .total {
    flex-direction: column-reverse;
  }

  .payment-content .total-price {
    text-align: end !important;
  }

  .payment-content .game-info {
    align-items: center;
  }

  .game {
    padding: 10px;
  }
  .payment-content .empty-cart {
    padding: 35px 0 35px 15px;
    font-size: 15px;
    line-height: 1.6;
    margin: 0;
  }

  .credit-img .qr-text {
    font-size: 16px !important;
    line-height: 1.8;
  }
  .store-tabs span {
    padding: 15px 5px !important;
    font-size: 12px !important;
  }
}
