.footer {
  width: 100%;
  background: linear-gradient(180deg, #0abeb9 -58.84%, #3a144e 100%);
  padding: 50px 25px;
}

.first-col {
  text-align: start;
}

.first-col .footer-desc {
  color: white;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.7;
}

.footer ul li a {
  display: inline-block;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-decoration: none;
}
.footer ul li a:hover {
  color: #6900ff;
  transition: all ease-in-out 0.4s;
  text-decoration: underline;
}
.contact {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.social-title {
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.footer .contact-title {
  display: block;
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: start;
  text-wrap: wrap;
}
.contact .inp {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.contact .subscribe-icon{
  position :absolute ;
  background: transparent;
  border: 0;
  right: 0;
  line-height: 1;
  height: 100%;
  font-size: 25px;
  
}
body[dir="rtl"] .contact .subscribe-icon{
  right: auto !important;
  left: 0 !important;
  transform: rotate(180deg);

}
.swal2-confirm{
  background-color: #3a144e !important;
}

.footer h2 span {
  margin: 20px 0;
  font-size: 30px;
  color: #fff;
}
.footer .playit-links {
  justify-content: flex-start;
}
.footer .playit-links img {
  width: 125px;
  height: 50px;
  border-radius: 10px;
}
.footer .contact-b {
  color: #fff;
  font-size: 22px;
}
.footer .footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.footer-icons a svg {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  color: black;
  cursor: pointer;
}
.footer-icons a svg:hover {
  background: rgba(58, 20, 78, 1);
  color: white;
  transition: all ease-in-out 0.2s;
}
@media (max-width: 525px) {
  .footer ul li a {
    font-size: 20px;
  }
}
