.gameHistory-img {
  width: 75px;
  height: 75px;
}
.gameHistory-name {
  font-size: 15px;
  width: 75px;
  text-align: center;
  font-weight: bold;
  text-wrap: nowrap;
}
