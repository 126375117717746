.games-slider {
  background: linear-gradient(180deg, #0abeb9 23.21%, #3a144e 179.41%);
  width: 95%;
  margin: auto;
  border-radius: 16px;
  z-index: 99;
  position: relative;
  top: -35px;
  margin-top: 0;
  padding: 50px 0;
  border: 1px solid #000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.slider-title {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  text-transform: capitalize;
  margin: auto;
  text-decoration: none;
}
.slider-title span {
  color: #ffc312;
}
.games-slider .card {
  background-color: transparent;
  border: none;
  padding: 10px;
}
.card-body {
  text-align: left;
  padding: 10px 0px;
}
.card-title {
  font-weight: bold;
  font-size: 20px !important;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-decoration: none;
}
.card-image {
  height: 250px;
  float: left;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
}
.card-text {
  font-size: 13px !important;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  line-height: 1.8;
  text-decoration: none !important;
}
.tokens {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background: #000;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  float: left;
  margin-right: 5px;
}
.tokens img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.price {
  padding: 5px 10px;
  font-weight: bold;
  font-size: 17px;
}
.games-slider a {
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-transform: capitalize;
  letter-spacing: 1px;
}
/* see all btn */
.seeAll-btn {
  padding: 0;
  margin: 0 auto;
  border: none;
  background: none;
  cursor: pointer;
}

.seeAll-btn {
  --hovered-color: rgba(58, 20, 78, 1);
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.seeAll-btn p {
  margin: 0;
  position: relative;
  font-size: 20px;
}

.seeAll-btn::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.seeAll-btn p::before {
  position: absolute;
  /*   box-sizing: border-box; */
  content: "";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.seeAll-btn:hover::after {
  width: 100%;
}

.seeAll-btn:hover p::before {
  width: 100%;
}

.seeAll-btn:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.seeAll-btn svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

/* mobile responsive */
@media (max-width: 525px) {
  .games-slider {
    top: -90px;
  }
  .card-image {
    float: none;
  }
  .card-title {
    font-size: 20px !important;
    margin-top: 15px !important;
  }
  .card-text {
    height: auto;
  }
  .slick-slide img {
    width: 100%;
  }
  .tokens {
    font-size: 15px;
  }
  .price {
    padding: 5px 0;
  }
}
