.play-button {
    --bezier: cubic-bezier(0.22, 0.61, 0.36, 1);
    --edge-light: rgba(58, 20, 78, 1);
    --text-light: rgba(255, 255, 255, 0.4);
    --back-color: 240, 40%;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 0.5em;
    height: 64px;
    min-width: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 1;
    font-weight: bold;
    background: black;
    color: hsla(0, 0%, 90%);
    border: 0;
    box-shadow: inset 0.4px 1px 4px var(--edge-light);
    transition: all 0.3s var(--bezier);
    text-decoration: none;
  }
  
  .play-button:hover {
    --edge-light: rgba(58, 20, 78, 1);
    text-shadow: 0px 0px 10px rgba(58, 20, 78, 1);
    box-shadow: inset 0.4px 1px 4px var(--edge-light),
      2px 4px 8px rgba(58, 20, 78, 1);
    transform: scale(1.1);
    background-color: rgba(58, 20, 78, 1);
    color: white;
    border: none;
  }
  
  .play-button:active {
    --text-light: rgba(255, 255, 255, 1);
  
    background: #f9a507;
    box-shadow: inset 0.4px 1px 8px var(--edge-light),
      0px 0px 8px hsla(var(--back-color), 50%, 0.6);
    text-shadow: 0px 0px 20px var(--text-light);
    color: hsla(0, 0%, 100%, 1);
    letter-spacing: 0.1em;
    transform: scale(1);
    border: none;
  }
  