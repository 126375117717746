.rate-btn {
    border: none;
    height: 5em;
    border-radius: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #1C1A1C;
    cursor: pointer;
    transition: all 0.3 ease-in-out;
  }
  
  .sparkle {
    fill: #AAAAAA;
    transition: all 800ms ease;
  }
  
  .rate-text {
    font-weight: 600;
    color: #AAAAAA;
    font-size: medium;
  }
  
  .rate-btn:hover {
    background: linear-gradient(0deg,#ffea88,#ffc312);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2),
    0px 0px 180px 0px #ffda4a;
    transform: translateY(-2px);
    color: black;
  }
  
  .rate-btn:hover .rate-text {
    color: black;
  }
  
  .rate-btn:hover .sparkle {
    fill: black;
    transform: scale(1.2);
  } 