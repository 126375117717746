.title {
    align-items: center;
}

.title h2 {
    font-size: 27px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 0;
    text-align: start;
}

.title-btn {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.title .btn-more {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: rgba(58, 20, 78, 1);
    border: none;
    outline: none;
    padding: 10px 25px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
}

/* see all button */
.see-all-btn {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: rgba(58, 20, 78, 1);
  color: #fff;
  border-radius: 10px;
  font-weight: bold;
  padding: 15px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color .3s;
  }
  
  .button__icon-wrapper {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    position: relative;
    color: rgba(58, 20, 78, 1);
    background-color: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  
  .see-all-btn:hover {
    background: rgba(58, 20, 78, 1);
    color: #fff;
  }
  
  .see-all-btn:hover .button__icon-wrapper {
    color: rgba(58, 20, 78, 1);
  }
  
  .button__icon-svg--copy {
    position: absolute;
    transform: translate(-150%, 150%);
  }
  
  .see-all-btn:hover .button__icon-svg:first-child {
    transition: transform .3s ease-in-out;
    transform: translate(150%, -150%);
  }
  
  .see-all-btn:hover .button__icon-svg--copy {
    transition: transform .3s ease-in-out .1s;
    transform: translate(0);
  }

@media (max-width:525px) {
    .title h2 {
        font-size: 18px;
    }

    .title .btn-more {
        font-size: 10px;
        padding: 10px 5px;
    }
    .see-all-btn{
      width: 100%;
      justify-content: center;
    }
}