.wishlist{
    span{
        color:grey;
        font-size: 14px;
        font-weight: 500;
    }
}
.shopping-check{
    float:right;
    font-size:16px
}