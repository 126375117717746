.info{
    width: 100%;
    /* height: 450px; */
    /* background-image: url(../../assets/background_1.png); */
    background-position: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.info .slider-container{
    width: 95%;
    margin: auto;
}
.info .lives-number{
    color: black;
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
    text-decoration: none;
}
.info .lives-number:hover{
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
}
.info-section{
    padding: 0 50px;
}
.info-details{
    text-align: left;
    margin-bottom: 0;
    color: white;
    font-size: 17px;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 1.8;
    letter-spacing: 1px;
    font-family: inherit;
}
.info-section .inp{
    width: 100%;
    padding: 15px 10px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
}
@media (max-width:525px) {
    .sub-package{
        padding-top: 0 !important;
    }
}