.overlay {
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0;
  justify-content: space-between;
}
.overlay h1 {
  color: white;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin: 0;
}
.skull img {
  width: 180px;
  height: 215px;
  margin-top: 20px;
}
.user-pic img {
  width: 100%;
  /* border: 2px solid black; */
  border-radius: 10px;
}
.user-info {
  text-align: start;
}
.user-info h1 {
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  height: 67px;
}
.user-list li,
.user-data li {
  color: rgba(0, 0, 0, 0.51);
  margin-bottom: 25px;
  font-size: 15px !important;
  font-weight: bold;
}
ul {
  padding: 0;
}
.user-data-1 li {
  color: rgba(191, 191, 191, 1);
  margin-bottom: 25px;
  font-size: 15px !important;
  font-weight: bold;
}
.btn-edit {
  background-color: black;
  padding: 10px;
  outline: none;
  border-radius: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  text-decoration: none;
  color: white;
}
.btn-cart {
  font-size: 25px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: black;
  margin-top: 10px;
  display: inline-block;
}
.game-history {
  display: none;
}
label {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1px;
}
.form-control {
  font-weight: bold;
  height: 55px;
  border: 2px solid rgba(133, 133, 133, 1);
  /* background: rgba(58, 20, 78, 1); */
  color: black;
}
/* edit profile btn */
.Btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 195px;
  height: 40px;
  border: none;
  padding: 0px 20px;
  background: rgba(58, 20, 78, 1);
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 5px 5px 0px rgba(255, 195, 18, 1);
  transition-duration: 0.3s;
}

.svg {
  width: 13px;
  position: absolute;
  margin-right: 20px;
  fill: white;
  transition-duration: 0.3s;
}

/* .Btn:hover {
    color: transparent;
  } */

/* .Btn:hover svg {
    left: 43%;
    margin: 0;
    padding: 0;
    border: none;
    transition-duration: .3s;
  } */

.Btn:active {
  transform: translate(3px, 3px);
  transition-duration: 0.3s;
  box-shadow: 2px 2px 0px rgba(255, 195, 18, 1);
}
/* view my cart btn */
.cssbuttons-io-button {
  background: rgba(58, 20, 78, 1);
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 10px;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em black;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: black;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}

/* rgba(133, 133, 133, 1); */
@media (max-width: 525px) {
  .overlay {
    padding: 0 10px;
  }
  .overlay h1 {
    font-size: 16px;
  }
  .cup img {
    width: 80px;
  }
  .btn-cart {
    font-size: 15px;
  }
  .user-data-1 {
    overflow: scroll;
  }
  .btn-edit {
    font-size: 15px;
  }
  .Btn {
    width: 100%;
    font-size: 12px;
  }
  .cssbuttons-io-button {
    font-size: 11px;
    padding: 5px;
    height: 40px;
    margin-bottom: 10px;
    width: 100%;
    letter-spacing: 1px;
  }
  .cssbuttons-io-button .icon {
    margin-left: 10px;
  }
}
