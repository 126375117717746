.aside {
    height: 100vh;
    background: linear-gradient(167.62deg, #82D9D5 34.59%, #457371 101.22%);
    box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.15);

}

.aside p {
    color: white;
    width: 100%;
    font-size: 20px;
    text-transform: capitalize;
    line-height: 1.8;
    font-weight: bold;
}
.aside .logo{
    margin-top: 80px !important;

}

/* .aside .angry_birds {
    width: 100%;
    height: 65vh;
} */

/* .aside .logo {
    margin: 35px auto 0 auto;
    cursor: pointer;
    width: auto;
    height: 25vh;
} */
 .description{
    position: absolute;
    top: 25%;
    font-size: 12px !important;
    font-weight: bolder;

 }
@media (max-width:525px) {
    .aside {
        height: 40vh !important;
        text-align: center;
        padding: 25px 20px;
    }
     .aside .logo {
        margin: 0 auto;
        margin-bottom: 0 !important;
        width: 290px !important;
        margin-top: 20px !important;
      } 
    .aside .angry_birds {
        display: none;
    }
   
}
@media (max-width:991px) {
    .aside {
        height: 40vh !important;
        text-align: center;
        padding: 25px 20px;
    }

    .aside .logo {
        margin: 0 auto;
        margin-bottom: 0 !important;
        width: 290px !important;
        margin-top: 20px !important;
    }

    .aside .angry_birds {
        display: none;
    }
          .description {
              font-size: 13px !important;
              top: 15%;
    
          }

}
@media (max-width: 575.98px) { 
    .description{
        font-size: 13px !important;
        top: 15%;
    
     }
     
 }

