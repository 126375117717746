@import "../../node_modules/bootstrap/scss/bootstrap";
// @import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');

html {
  max-width: 100dvw;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
body {
  background: url(../assets/imgs/bg.svg) center center no-repeat;
  min-height: 100dvh;
  max-width: 100dvw;
  overflow-x: hidden;
  width: 100%;
  background-size: cover;
  position: relative;
//   font-family: "Rowdies", sans-serif;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(34, 78, 144, 0.4);
  }
}
.main-layout {
  .serviceTitle {
    position: relative;
    // width: 100%;
    // min-width: 200px;
    height: 100%;
    text-align: center;
    // background: url(../assets/imgs/Asset\ 7.svg) center center no-repeat;
    // background-size: cover;
    .servicebg {
      height: 160px;
      max-width: 100%;
    }
    .cycleBorder {
      padding: 15px;
      position: relative;
    }
    .serviceName {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: #ffd800; /* Fallback for browsers that don't support text-fill */
      -webkit-text-fill-color: #ffd800; /* Fill color */
      -webkit-text-stroke-width: 2px; /* Stroke width */
      -webkit-text-stroke-color: #224e90; /* Stroke color */
      font-size: 48px; /* Adjust font size as needed */
      font-weight: bold; /* Optional: makes the text thicker */

      text-shadow: 2px 2px 0px rgb(34, 78, 144),
        4px 4px 0px rgb(66, 172, 214, 0.8), 3px 3px 0px rgba(34, 78, 144, 0.5),
        8px 8px 10px rgba(0, 0, 0, 0.3);

      margin: 0;
      //   padding: 25px 100px;
      img {
        max-width: 135px;
      }
    }
  }
  .body-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #224e90;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 50px;
    .servImg {
      max-width: 100px;
    }
    .input-group{
      direction: ltr;
    }
    .btn-sub {
      position: relative;
      .btn-bg {
        height: 100%;
        width: 200px;
      }
      .btn-txt {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #623D8C;
        font-weight: 800 ;
      }
    }
  }
  .language-switcher{

    position: absolute;
    right: -30px;
    top: 15px;
    z-index: 55;
    select{
        background: transparent;
        color: #ffd800;
        border: 0;
        option{
            color: #224e90;
        }
    }
   
    @media screen and (max-width: 600px) {
        right: 15px !important;
        top: 15px;
    }
  }

}
