.tournaments {
  background: #0abeb9;
  padding: 30px 0px;
}

.tournaments-section {
  background-color: #000;
  padding: 35px 45px;
}
.section-title {
  font-size: 70px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  margin: 50px 0;
}

.gradient-text {
  text-align: center;
  /* Fallback: Set a background color. */
  background-color: black;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #fff, #000);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.tournaments-games .game-item > img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  margin-bottom: 0;
}
.tournaments-games .btn-join {
  background: linear-gradient(180deg, #0abeb9 -58.84%, #3a144e 100%);
  border-radius: 10px;
  color: white;
}
.right-arrow {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  margin: 1%;
}
.right-arrow a {
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  letter-spacing: 1px;
}
.tournaments .btn-join {
  width: 75%;
  margin: auto;
  cursor: pointer;
}
@media (max-width: 525px) {
  .section-title {
    letter-spacing: 1px;
    font-size: 20px;
    margin: 50px auto;
  }
}
