.react-tabs__tab-list{
    display: flex;
    margin: 25px 0;
}
.react-tabs__tab{
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 1px;
}
.react-tabs__tab--selected{
    color: red;
}
.react-tabs__tab-panel p{
    font-size: 19px;
    font-weight: 600;
    font-family: sans-serif;
    line-height: 1.9;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #aaa;
    text-align: justify;
}
@media (max-width:525px) {
    .react-tabs__tab-panel p{
        font-size: 14px;
    }
}