/* .html-games{
    margin-top: 100px;
} */
.html-games .battles-games .game-item{
    position: relative;
}

.html-games .battles-games .game-item .btn-join
{
    position: absolute;
    bottom: 40px;
    left: 10px;
    max-width: fit-content;
    cursor: pointer;
}
.html-games .battles-games:last-child{
    margin-top: 0;
}
@media (max-width:525px) {
    .home-html{
        margin-top: 0 !important;
    }
}