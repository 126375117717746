.side-section,.war-section{
    background: linear-gradient(179.79deg, #0ABEB9 -58.43%, #3A144E 139.14%);
    background-repeat: no-repeat;
    min-height: 775px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}
.modal-content {
    height: auto;
    border-radius: 27px;
    background: linear-gradient(179.79deg, #0ABEB9 -58.43%, #3A144E 139.14%);
}
.crown{
    margin-bottom: 20px;
}
.side-section .avatar{
    margin-left: 10px;
    z-index: 99;
    position: relative;
    width: 130%;
    margin-top: -100px;
}
.war-name{
    color: white;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.rules-icon{
    max-width: 250px;
}
.text-rules{
    text-align: left;
}
body[dir="rtl"] .text-rules{
    text-align: right;
}

.kunai{
    color: #B39F9A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5px;
}
.inb-back{
    background-image: url('../../assets/theWar/inb_back.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    line-height: 45px;
    display: block;
    width: -moz-fit-content;
    width: 100%;
    margin: 0 10px;
    padding: 15px 10px;
    font-weight: bold;
}
.first-btn,.second-btn,.third-btn{
    width: 80%;
    margin: 0 auto 20px auto;
    padding: 10px;
    outline: none;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    border-radius: 10px;
}
.no-leaderboard{
    text-align: center;
    padding-top: 50px;
    color: white;
    letter-spacing: 2px;
    text-transform: capitalize;
}
.first-btn{
    background-color: #FFC800;
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}
.second-btn{
    border: 1px solid #FFC800;
    background-color: transparent;
    color: #FFC800;
    text-decoration: underline;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}
.third-btn{
    color: white;
    text-decoration: underline;
    background: none;
    border: none;
}
.winners-list{
    color: white;
    border: 1px solid transparent;
}
.winner-img{

    background-size: cover;
    background-position: center;
    
    text-align: center;
}
.list-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.top-winner-img{
    width: 90px;
    height: 90px;
}
.winners-list h2{
    color: white;
    font-size: 17px;
    margin-bottom: 0;
}
.star-btn{
    width: 60%;
    padding: 5px;
    background-color: #E6991C;
    border-radius: 10px;
    position: relative;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
}
.star-btn img{
    position: absolute;
    top: -7px;
    left: 4px;
}
.winners-list .active{
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFC800;
}
.back-btn{
    padding: 10px 40px;
    border-radius: 10px;
    background-color: transparent;
    font-size: 17px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    outline: none;
}
.game_img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: auto;
}
/* winners section */
.winners-list{    
    width: 95%;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.31);
    border-radius: 10px;
    margin: 10px auto;
    overflow-y: scroll;
}

.winners-img{
    width: 145px;
    height: 145px;
    line-height: 145px;
    background-image: url("../../assets/theWar/winner_back.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.first-winner{
    height: 125px;
}

.winner h2{
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: auto;
    /* text-wrap: wrap; */
    overflow: hidden;
}
.img-winner{
    z-index: 9999;
}
::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar{
    background-color: transparent;
}
::-webkit-scrollbar-thumb{
    background-color: #FFC800;
    border-radius: 10px;
}
.winner-number{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: rgb(255, 255, 255);
    margin-bottom: 0px;
}
.winner-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}
/* mobile responsive */
@media (max-width: 525px) {
    .no-leaderboard{
        padding-top: 0;
        padding: 25px 10px;
        text-align: left;
        font-size: 15px;
    }
    .winners-list{
        width: 95%;
        height: 315px;
        overflow-y: scroll;
    }
    .winners-list h4{
        font-size: 10px;
    }
 .side-section .avatar{
    margin-left: 0px;
    width: 100%;
 }
 .side-section, .war-section{
    height: auto;
 }
 /* .war-section{
    background-color: #714335 !important;
    background-image: none !important;
 } */
 .star-btn{
    width: 100%;
    font-size: 12px;
 }
 .winner-img img{
    width: 100%;
 }
 .winners-list h2{
    font-size: 10px;
    margin-bottom: 0;
 }
.winner-group {
   display: flex;
   align-items: center;
 }
 /* 3 winner section  */
 .winners .winner .winners-img img{
    width: 50px;
    height: 50px;
 }
 .winner h2{
    font-size: 10px;
    padding: 5px;
 }
 .winners-img{
    width: 100%;
    background-size: contain;
 }
 .img-winner{
    background-size: contain;
    z-index: 99999;
    position: relative;
 }
 .crown{
    width: 100%;
 }
}