@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
.tabs ul {
  display: flex;
  align-items: center;
  gap: 15px;
}
.tabs li,
.inv {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: black;
  border: 1px solid #000;
  padding: 12px 0;
  border-radius: 10px;
  width: 20%;
  margin-bottom: 10px;
  text-align: center;
}
.tabs .active,
.inv {
  background: rgba(58, 20, 78, 1);
  color: rgba(255, 255, 255, 1) !important;
}

.content {
  display: none;
}
.show-content {
  display: block;
}
.game-history {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.08);
  margin: 15px auto;
  border-radius: 20px;
  padding: 30px;
}
.game-voucher {
  margin: 40px auto;
}
.game-content {
  background-color: white;
  border-radius: 20px;
  padding: 20px 10px;
  height: 100%;
}
.game-content p {
  text-align: center;
  color: rgba(0, 0, 0, 0.29);
}
.game-content table {
  overflow-x: scroll;
}
.game-content td {
  text-align: center;
  color: rgba(0, 0, 0, 0.29);
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: capitalize;
}
.table tr th {
  text-align: center;
  padding-bottom: 20px;
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  letter-spacing: 1px;
}
.see-all {
  color: rgba(0, 0, 0, 0.51);
  width: 100%;
  display: block;
  margin-top: 15px;
  text-align: center;
}
.versus {
  display: inline-block;
  margin: 0 10px;
}
/* voucher */
.card {
  position: relative;
}
.card-title {
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}
.card-text {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0;
  line-height: 1.8;
}
.card-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-buttons .reorder {
  height: 45px;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(255, 195, 18, 1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  gap: 20px;
}
.card-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code {
  border: 1px solid rgba(0, 0, 0, 0.28);
  padding: 5px;
  border-radius: 10px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.heart {
  text-align: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: rgba(197, 38, 38, 1);
  border-radius: 50%;
  cursor: pointer;
}
@media (max-width: 525px) {
  .card-titles {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  .game-history {
    padding: 20px;
  }
  .tabs li,
  .inv {
    font-size: 10px;
    width: 33%;
  }
}
