.otp {
    text-align: center;
}
.otp h1{
    margin-top: 80px;
    font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.otp p{
    text-align: center;
}
.otp h2{
    line-height: 2;
    margin: 25px;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
    color: rgba(77, 77, 77, 0.8);
}
.user-phone{
    color: #000;
}
.inputs{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px auto;
}
.inputs input {
    width: 70px;
    height: 80px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    padding: 0 5px;
    text-align: center;
}
.otp p{
    font-size: 17px;
    display: block;
    margin: 15px;
    color: rgba(70, 70, 70, 1);
    font-weight: bold;
}
.otp p button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
}
h3 a{
    color: #000;
    text-decoration: none;
    font-size: 15px;
}
.otp-inp{
    width: 59px  !important;
    height: 68px !important;
    
}
.signUp input[type="number"]::-webkit-inner-spin-button,
.signUp input[type="number"]::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
@media (max-width:525px) {
    .otp h1{
        margin: 15px 0;
        font-size: 25px;
    }
    .otp h2{
        margin: 15px;
    }
}