/* side-bar */
.side-bar{
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 99999999999;
    text-align: left;
    font-family: 'Inter', sans-serif;
    left: -560px;
    transition: all ease-in-out 0.5s;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
}
.side-bar-content{
    background-color: white;
    position: relative;
}
.user-data{
  background: linear-gradient(189.33deg, #44BBB6 31.42%, #3A144E 111.32%);
  position: relative;
  }
.sidebar-close {
  position: absolute;
  top: 10px;
  cursor: pointer;
  z-index: 999;
  background: linear-gradient(189.33deg, #44BBB6 31.42%, #3A144E 111.32%);
  padding: 10px;
  border-radius: 100px;
}
.close img{cursor: pointer;}
.user-details{
    padding: 25px 0 0 0;
}
.user-details .user-info{
    float: left;
    margin-right: 10px;
    margin-left: 5px;

    text-align: center;
}
.user-info .user-img{
    width: 100%;

}
.user-info .user-img img{
    width: 100px;
    height: 100px;
    cursor: pointer;
}
.user-info .user-email{
    font-size: 12px;
}
.golden{
    color: #000;
    background: rgba(255, 212, 0, 1);
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid rgba(247, 247, 247, 1);
    font-size: 13px;
    font-weight: bold;
    margin: auto;
    width: fit-content;
    text-transform: capitalize;
}
.user-name span{
    display: block;
    color: white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 5px;
}
.user-name .user-email{
    font-size: 16px;
    margin-top: 10px;
}
.coins span img{
    width: 25px;
    height: 25px;
}
/* nav links */
.nav-links{
  padding: 0px 50px 0 50px
}
.nav-links .nav-link {
  margin-bottom: 15px;
  text-align: start;
}
.nav-link a{
    display: block;
    font-size: 20px;
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
    text-decoration: none;
}
.nav-link:last-child .nav-link::after{
  display: none;
}
.nav-link a.active{
    color: rgb(244 191 0);
    font-weight: bold;
}
.nav-link::after{
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: rgba(253, 163, 26, 0.23);
    margin-bottom: 20px;
}

/* user custom */
.user-custom{
    padding: 17px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-top: 50px;
    border-top: 2px solid rgba(0, 0, 0, 0.72);
    margin: 15px 50px 0 50px;
}
.user-logout,.user-settings {
    display: flex;
    gap: 10px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    color: black;
    text-decoration: none;
}
.user-logout img,.user-settings img {
    width: 25px;
    height: 25px;
}
/* active side-bar */
.activ {
    left: 0;
    width: 100%;
}
/* logout button */
.logout-btn {
    --black: #000000;
    --ch-black: #141414;
    --eer-black: #1b1b1b;
    --night-rider: #2e2e2e;
    --white: #ffffff;
    --af-white: #f3f3f3;
    --ch-white: #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: var(--af-white);
  }
  
  /* plus sign */
  .sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sign svg {
    width: 17px;
  }
  
  .sign svg path {
    fill: var(--night-rider);
  }
  /* text */
  .text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: var(--night-rider);
    font-size: 1.2em;
    font-weight: 600;
    transition-duration: .3s;
  }
  /* hover effect on button width */
  .logout-btn:hover {
    width: 125px;
    border-radius: 5px;
    transition-duration: .3s;
  }
  
  .logout-btn:hover .sign {
    width: 30%;
    transition-duration: .3s;
  }
  /* hover effect button's text */
  .logout-btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
  /* button click effect*/
  .logout-btn:active {
    transform: translate(2px ,2px);
  }
/* settings btn */
.settings-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: none;
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
  }
  .settings-btn .svg-icon{
    width: 30px;
    height: 30px;
  }
  .settings-btn .lable {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    font-family: sans-serif;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  
  .settings-btn:hover .svg-icon {
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
/* mobile responsive */
@media (max-width:525px) {
  .user-details{
    padding: 45px 0 0 0;
  }
  .sidebar-close{
    background-color: #000;
    border-radius: 50%;
    padding: 10px;
  }
  .user-info .user-img img{
    width: 75px;
    height: 75px;
  }
    .nav-links{
        padding: 10px 25px 0 25px;
    }
    .user-details .user-info{
      margin-right: 0px;
    }
    .side-bar-content{
      width: 100%;
    }
    .user-custom{
      margin: 0px 20px 0 25px;
      flex-direction: row;
      align-items: flex-start;
    }
    .user-name span{
      font-size: 15px;
    }
    .user-name .user-email{
      font-size: 11px;
    }
}