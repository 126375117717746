@keyframes ldio-tecz3j4dcy-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-tecz3j4dcy-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-tecz3j4dcy > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-tecz3j4dcy > div:nth-child(2) div {
  position: absolute;
  top: 39px;
  left: 39px;
  width: 117px;
  height: 58.5px;
  border-radius: 117px 117px 0 0;
  background: #ffc800;
  animation: ldio-tecz3j4dcy-1 0.9345794392523364s linear infinite;
  transform-origin: 58.5px 58.5px
}
.ldio-tecz3j4dcy > div:nth-child(2) div:nth-child(2) {
  animation: ldio-tecz3j4dcy-2 0.9345794392523364s linear infinite
}
.ldio-tecz3j4dcy > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-tecz3j4dcy-3 {
    0% { transform: translate(185.25px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(68.25px,0); opacity: 1 }
}
.ldio-tecz3j4dcy > div:nth-child(1) {
  display: block;
}
.ldio-tecz3j4dcy > div:nth-child(1) div {
  position: absolute;
  top: 89.7px;
  left: -7.8px;
  width: 15.6px;
  height: 15.6px;
  border-radius: 50%;
  background: #e15b64;
  animation: ldio-tecz3j4dcy-3 0.9345794392523364s linear infinite
}
.ldio-tecz3j4dcy > div:nth-child(1) div:nth-child(1) { animation-delay: -0.7169000000000001s }
.ldio-tecz3j4dcy > div:nth-child(1) div:nth-child(2) { animation-delay: -0.3531s }
.ldio-tecz3j4dcy > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-f5k2yix57mv {
  margin: auto;
  width: 195px;
  height: 195px;
  display: inline-block;
  overflow: hidden;
}
.ldio-tecz3j4dcy {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-tecz3j4dcy div { box-sizing: content-box; }