
.signup-section p{
    font-size: 11px !important;
}

.signup-section h5{
    font-size: 13px !important;
    font-weight: bold;
}
.signup-section h6 {
    font-size: 12px;
}
.signup-section input,.signup-section select{
    border: 1px solid rgba(0, 0, 0, 0.63) !important;
}
.signup-section{
    /* background: linear-gradient(-180deg,rgb(56, 191, 224),rgb(18, 24, 36)); */
    min-height: 100vh;
}
.signup-section .des{
    font-weight: bolder;
    text-transform: uppercase;
}
.signup-section ::placeholder{
    font-weight: 100;
}
.signup-section select {
    color: #aaa !important;
    font-size: 16px !important;
}
.signup-section ul{
    font-size: 12px !important;
}
.signup-section li{
    list-style: disc !important;
    position: relative;
    padding-left: 5px;

}





.signup-section button{
    background-color: #3a144e !important;
}
.signup-section .message >  .error-msg {
    font-size: 12px !important ;
} 