/* creadit card */
.rccs{
    width: 100% !important;
}
.rccs__card{
    width: 95% !important;
    height: 250px !important;
}
.paymob{
    display: block;
    text-align: left;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    color: black;
    cursor: pointer;
    text-decoration: underline;
}
.paymob:hover{
    color: red;
    transition: all ease-in-out 0.4s;
}
@media (max-width:525px) {
    .rccs__card{
        height: auto;
    }
}