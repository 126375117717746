@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.nav-comp {
  background: linear-gradient(180deg, #0abeb9 -58.84%, #3a144e 100%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  color: #fff;
  height: 100px;
}
.nav-comp .logo h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.play_it {
  cursor: pointer;
}
.user-login-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: bold;
  height: 100%;
}
.nav .nav-title {
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Inter";
  font-size: 27px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.user-login-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.user-login-img a {
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
}
.user-login-img .user-img {
  width: 45px;
  height: 45px;
  margin: auto;
}
.user-login-coins {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.user-coins {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-coins img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}
.user-heart {
  margin-bottom: -10px;
}
.nav-details {
  padding: 24px 0;
}
.menu-icon {
  display: flex;
  align-items: center;
  height: 100%;
}
.menu_icon {
  cursor: pointer;
}
.logo h2 {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
}
.btn {
  color: white;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  border: none;
}
.btn-sign-up {
  padding: 5px 10px;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  outline: none;
  border-radius: 20px;
  background-color: white;
  text-decoration: none;
  border-radius: 20px;
}
/* btn-cart */
.cart-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  position: relative;
}

.cart-icon::after {
  content: attr(data-quantity);
  width: fit-content;
  height: fit-content;
  position: absolute;
  font-size: 15px;
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s linear;
  top: 115%;
}

.icon-cart {
  width: 24.38px;
  height: 30.52px;
  transition: 0.2s linear;
}

.icon-cart path {
  fill: white;
  transition: 0.2s linear;
}

.cart-icon:hover > .icon-cart {
  transform: scale(1.2);
}

.cart-icon:hover > .icon-cart path {
  fill: rgba(255, 195, 18, 1);
}

cart-icon:hover::after {
  visibility: visible;
  opacity: 1;
  top: 105%;
}

.quantity {
  display: none;
}

@media (max-width: 525px) {
  .nav-comp .logo h1 {
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
  }
  .btn {
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
  }
  .play_it {
    height: 44px;
  }
  .nav-details .play_it {
    width: auto;
  }
  .btn-sign-up {
    padding: 5px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    outline: none;
    border-radius: 20px;
  }
  .menu-icon img {
    width: 100%;
  }
  .user-login-info {
    justify-content: flex-end;
  }
  .user-login-img {
    width: 30px;
    height: 30px;
    line-height: 0;
  }
  .user-login-img .user-img {
    width: 30px;
    height: 30px;
  }
  .user-coins img {
    width: 15px;
    height: 15px;
  }
  .cart-icon {
    width: 30px;
    height: 30px;
  }
  .user-login-info {
    font-size: 12px;
  }
  .nav .nav-title {
    font-size: 15px;
    letter-spacing: 0px;
  }
}
