.form {
    height: 100vh;
    text-align: center;
}

.form h1 {
    margin: 0 0 35px 0;
    font-size: 35px;
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.google {
    width: 100%;
    margin: 20px auto;
    border: 2px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Rajdhani", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
    cursor: pointer;
}

.form img {
    width: 25px;
    margin-right: 10px;
}

.form span {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 25px;
    font-family: "Rajdhani", sans-serif;
    text-transform: uppercase;
    color: #ddd;
    font-weight: bold;
}

.signUp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: none;
    height:6rem;

}

.input{
    height:3rem;
    /* margin-bottom:0.5rem; */
}

.signUp-form .phone {
    width: 100%;
    
    border:1px solid #ddd ;

    padding: 10px;
    font-family: "Rajdhani", sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: capitalize;
    outline: none;
    margin-bottom: 10px;
}

.submit-info {
    width: 100%;
    margin: auto;
}

.signUp .btn-submit {
    width: 100%;
    padding: 7px;
    background: rgba(58, 20, 78, 1);
    color: white;
    font-size: 23px;
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    border-radius: 5px;
    margin-bottom: 10px;

}

.submit-info h3 {
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: "Rajdhani", sans-serif;
    color: rgba(160, 160, 160, 1);
    text-align: center;
}

h3 a {
    color: #000;
    text-decoration: none;
    font-size: 17px;
    letter-spacing: 1px;
    font-family: "Rajdhani", sans-serif;
}

.alert-danger {
    font-size: 14px;
    font-weight: bold;
    font-family: "Rajdhani", sans-serif;
}