.thanks-section{
    background-color: rgba(226, 226, 226, 1);
    border-radius: 30px;
    border: 1px solid rgba(249, 192, 95, 0.38);
}
.submit-info .btn-submit{
    width: 50%;
    height: 65px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 10px;
}
@media (max-width:525px) {
    .rate-section h1{
        font-size: 20px !important;
        text-align: center;
    }
    .rate-section p{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
    }
    .check{
        margin: 0;
    }
}