select{
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 0 10px;
    background: rgba(230, 191, 0, 1);
    color: #000;
    font-weight: bold;
    font-size: 15px;
    text-transform: capitalize;
}
option:disabled {
    color: black;
    font-weight: bold;
    background-color: #fff;
}
.modal.show .modal-dialog {
    top: 50px;
    color: white;
    text-align: center;
}

.modal {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2147483647;
}

.modal-header {
    position: relative;
    height: 90px;
}

.modal-title {
    position: absolute;
    top: -50px;
}

.btn-primary{
    font-size: 15px !important;
}

.btn-close {
    color: white;
}

.modal-header {
    border-bottom: none;
}

.modal-body h2 {
    color: rgba(255, 255, 255, 0.94);
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 51px;
    text-transform: capitalize;
}

.modal-body p {
    color: rgba(255, 255, 255, 0.94);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-transform: capitalize;
    /* text-align: start !important; */
}

.modal .code-inp {
    background-image: url('../../assets/btn_back.png');
    color: #0E1531;
    font-family: Poppins;
    font-size: 27px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    background-color: transparent;
    height: 73px;
    border: none;
    outline: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    text-align: center;
}

.code-inp::placeholder {
    color: #0E1531;
    font-family: Poppins;
    font-size: 27px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.coin-btn {
    width: 100%;
    text-align: center;
    margin: auto;
    background: rgba(58, 20, 78, 1);
    color: #fff;
    border-radius: 10px;
    height: 68px;
    line-height: 68px;
    font-size: 23px;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
}

.coin-btn img {
    width: 40px;
    height: 40px;
    margin: 0px 7px;
}

.modal-btns span {
    background-image: url('../../assets/inp_back.png');
    display: block;
    width: 100%;
    height: 96px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    line-height: 70px;
    color: black;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 1px;
}

.top-rank{
    background-image: url('../../assets/btn_border.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    width: 200px;
    margin: 25px auto;
    border: 2px solid #FFB915;
    border-bottom: none;
    padding: 5px;
    color: #FFC800;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1px;
}

/* dropdown menu button */
.btn-group{
    width: 100%;
    height: 50px;
}
.btn-primary{
    text-align: left;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
}
.btn-primary:nth-child(2) {
    text-align: right;
    width: 50px;
}
.inp-tourn-name{
    background: rgba(230, 191, 0, 1);
    height: 50px;
    color: #000;
    outline: none;
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    font-weight: bold;
}
.inp-tourn-name::placeholder{
    color: #000;
}
.modal-close-btn{
    position: absolute;
    right: 10px;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    top: 10px;
    background-color: #ffc800;
    border-radius: 5px;
    color: black;
}
.modal-close-btn:hover{
    background-color: black;
    color: #ffc800;
    transition: all ease-in-out 0.3s;
}
@media (max-width: 525px) {
    .modal-title{
        top: -35px ;
    }
    .modal-header{
        height: 75px;
    }
    .modal-body h2{font-size: 17px;line-height: 2;}
    .modal-body p{
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
    }
    .modal-btns span{
        width: 90%;
        height: 80px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        line-height: 54px;
        color: black;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 1px;
        padding: 15px 0;
    }

}