.shopping {
  background-color: #e2e2e2;
  padding: 20px;
  width: 100%;
  margin: auto;
  border-radius: 20px;
}

.shopping .game-infos {
  align-items: flex-start;
}

.shopping-back,
.shopping-check {
  padding: 10px 25px;
  background: rgba(58, 20, 78, 1);
  color: white;
  font-size: 25px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
}

.shopping-back {
  background-color: white;
  color: black;
}

.add .qty-inp {
  color: white;
  border-radius: 20px;
  background: rgba(58, 20, 78, 1);
  padding: 10px 10px;
  font-size: 13px;
  font-weight: bold;
}

.qty-inp::placeholder {
  color: white;
}

.add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.game-infos img {
  width: 125px;
  height: 75px;
  margin-right: 10px;
}

.empty-cart {
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
  color: red;
  text-align: start;
}

.game-infos p {
  font-weight: bold;
  font-size: 20px;
}
/* delete icon */
.tooltipp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 0px solid transparent;
  background-color: rgba(100, 77, 237, 0.08);
  border-radius: 10px;
  transition: all 0.2s linear;
  margin: 0px 5px;
}

.tooltipp:hover {
  box-shadow: 3.4px 2.5px 4.9px rgba(0, 0, 0, 0.025),
    8.6px 6.3px 12.4px rgba(0, 0, 0, 0.035),
    17.5px 12.8px 25.3px rgba(0, 0, 0, 0.045),
    36.1px 26.3px 52.2px rgba(0, 0, 0, 0.055),
    99px 72px 143px rgba(0, 0, 0, 0.08);
}

.tooltipp {
  position: relative;
  display: inline-block;
}

.tooltipp .tooltiptext {
  visibility: hidden;
  width: 4em;
  background-color: rgba(0, 0, 0, 0.253);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 110%;
}

.tooltipp .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.253) transparent transparent;
}

.tooltipp:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 525px) {
  .shopping-check {
    font-size: 18px;
    padding: 15px 0;
    border-radius: 10px;
  }
  .game-infos img {
    margin-right: 10px;
  }

  .list-title {
    font-size: 22px;
  }

  .game-infos p {
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 0;
  }

  .game-price {
    font-size: 15px !important;
    line-height: 40px;
    font-weight: bold;
  }

  .add {
    display: flex;
    align-items: center;
  }

  .add .qty-inp {
    font-size: 12px;
    padding: 10px 7px;
  }
  .empty-cart {
    margin: 10px 0;
    font-size: 15px;
    line-height: 1.7;
  }
}
