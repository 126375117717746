.rate-section {
    background-color: rgba(226, 226, 226, 1);
    border-radius: 30px;
    border: 1px solid rgba(249, 192, 95, 0.38);
}
.rate-section h1{
    font-size: 50px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
}
.rate-section p{font-size: 25px;}
.stars{
    text-align: center;
}
.stars .star {
    width: 45px;
    height: 45px;
    margin-right: 7px;
    cursor: pointer;
}
.text-msg{
    width: 100%;
    height: 250px;
    margin: auto;
    border: 2px solid rgba(133, 133, 133, 1);
    border-radius: 4px;
    resize: none;
    margin-bottom: 15px;
    color: rgba(133, 133, 133, 1);
    padding: 10px 15px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 18px;
}
.rate-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.rate-btns .play-button{
    width:100%
}
/* .rate-btns button{
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 6px;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
} */
@media (max-width:525px) {
    .rate-section h1{
        font-size: 35px;
        text-align: center;
    }
    .stars{
        text-align: center;
    }
    .stars .star{
        width: 30px;
        height: 30px;
    }
    .text-msg{
        width: 100%;
    }
    .rate-btns button{
        font-size: 15px;
    }
}