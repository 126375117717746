.position-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 25px 0;
}
.position-heading .heading-inp-check {
  width: 25px;
  height: 25px;
}
.wish-heading {
  text-align: right;
  text-decoration: underline;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;
  margin-bottom: 30px;
}
.wishlist {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}
.wishlist-heading {
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
}
.wishlist .wishlist-game {
  margin-top: 10px;
}
.wishlist-game h3 {
  text-align: start;
  font-size: 17px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.wishlist-game p {
  text-align: start;
  font-size: 15px;
  font-weight: bold;
  color: rgba(217, 221, 226, 1);
  text-transform: capitalize;
}
.wishlist .wishlist-price {
  background: black;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  display: inline-block;
}
.wishlist .wishlist-remove {
  font-size: 17px;
  font-weight: bold;
  text-decoration: underline;
}
.wishlist-remove {
  cursor: pointer;
}
.wishlist-item {
  position: relative;
}
.inp-check {
  position: absolute;
  top: -5px;
  width: 20px;
  height: 20px;
}
.wishlist-game-img {
  width: 100%;
  height: 99px;
  border-radius: 10px;
}
.card-img-top {
  width: 100%;
  height: 250px;
}
.profile .card-body {
  padding: 10px;
}
.profile .card-title {
  font-size: 19px;
}
.profile .card-text {
  height: 125px;
}
.wishlist-item-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 525px) {
  .wishlist {
    text-align: center;
    padding: 5px;
  }
  .wishlist .wishlist-game {
    margin-top: 0;
  }
  .wishlist .wishlist-game p {
    display: none;
  }
  .wish-heading {
    text-align: center;
    font-size: 20px;
  }
  .wishlist-item-price {
    flex-direction: row;
    justify-content: space-between;
  }
}
