.pay-succ{
    background-color: rgba(0, 0, 0, 0.08);
    margin: 50px auto;
    border-radius: 20px;
    padding: 85px 0;
    text-align: center;
}
.pay-succ svg{
    color: rgb(22 163 74);
    font-size: 85px;
}
.pay-succ .error{
    color: red;
}
.pay-succ h1{
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 10px 0;
}
.pay-succ span{
    display: block;
    font-weight: 500;
    margin: 20px 0;
    font-size: 17px;
}
.inputs input {
    width: fit-content;
    height: 80px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    padding: 0 5px;
    text-align: center;
}
.pay-succ p{
    font-size: 17px;
    display: block;
    margin: 20px 0px;
    color: rgba(70, 70, 70, 1);
    font-weight: 500;
}

.pay-succ .btn-submit{
    background-color: black;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
}
.pay-succ .btn-submit:hover{
    opacity: 0.7;
    transition: all ease-in-out 0.2s;
}
@media (max-width:525px) {
    .pay-succ h1{
        font-size: 20px;
    }
    .pay-succ p{
        font-size: 18px;
        margin: 0;
    }
}