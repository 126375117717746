@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.setting-side {
  height: 700px;
  background: linear-gradient(180deg, #0abeb9 -58.83%, #3a144e 135.04%);
  margin-bottom: 50px;
  border-radius: 23px;
  padding: 50px 35px;
  text-align: start;
  color: white;
}
.user-image {
  border: 3px solid rgb(249 192 95);
  border-radius: 50%;
  width: 160px;
  height: 160px;
}
.setting-icon {
  cursor: pointer;
  margin-bottom: 40px;
}
.setting-icon img {
  width: 25px;
  margin-right: 5px;
}
.setting-icon span {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  text-wrap: nowrap;
}
.setting-icon:first-child span {
  font-size: 24px;
  letter-spacing: 1px;
}
.user-edit {
  min-height: 700px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 23px;
  padding: 25px 0;
}
.user-setting-pic {
  position: relative;
  width: 160px;
  height: 160px;
  margin: 0 auto 20px auto;
}
.user-setting-pic > img {
  width: 100%;
}
.user-setting-pic .pen {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgb(249 192 95);
  border-radius: 6px;
  width: 36px;
  padding: 3px;
  cursor: pointer;
}
.user-form {
  width: 80%;
  margin: auto;
  text-align: start;
}

.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 35px 10px;
}
.notification span {
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 1px;
}
.form-switch .form-check-input {
  width: 66px;
  height: 32px;
  background-color: rgba(198, 154, 154, 1);
  cursor: pointer;
}
.user-edit p.terms {
  padding: 0 30px;
  /* text-align: justify;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: rgba(133, 133, 133, 1); */
  font-family: "Inter", sans-serif;
}
.license {
  display: flex;
  padding: 15px 35px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.39);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}
.license .form-check-input {
  border: 2px solid rgba(0, 0, 0, 0.28);
  width: 25px;
  height: 25px;
  margin: 0;
  cursor: pointer;
}
.license .form-check-input:checked {
  background-color: rgba(0, 0, 0, 0.39);
}
.hide-content {
  display: none;
}
.setting-side .setting-icon.activ {
  color: rgba(58, 20, 78, 1);
  width: auto;
}
.settings .voucher-btn {
  width: 100%;
  font-size: 20px;
  transition: all ease-in-out 0.3s;
}
/* mobile responsive */
@media (max-width: 525px) {
  .settings {
    padding-top: 100px !important;
  }
  .setting-side span {
    display: none;
  }
  .setting-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: auto;
    margin-bottom: 25px;
    padding: 25px;
    margin-top: 25px;
  }
  .setting-icon {
    margin-bottom: 0;
  }
  .user-edit {
    height: auto;
    padding: 20px 0;
  }
  .user-edit p.terms {
    padding: 0 20px;
    text-align: justify;
    font-size: 15px;
    margin-bottom: 20px !important;
  }
  .license {
    padding: 15px;
    font-size: 15px;
    text-align: left;
  }
}
