@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');
/* pagination */
.pay .form-select{
    background-color: #0d151e;
    background-image: none;
    appearance: auto;
    color: #fff;
    font-weight: bold;
    padding: 0px 0px;
    height: 40px;
    border: 2px solid #0d6efd75;
    outline: none;
    cursor: pointer;
    margin-top: 15px;
}
.pay .pagination{
    margin-bottom: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.pay .pagination .page-item{
    margin-bottom: 5px !important;
}
.pay .pagination .page-link:hover{
    background-color: rgba(255, 195, 18, 1);
    color: white !important;
    border: 1px solid rgba(255, 195, 18, 1);
}
.pay .pagination .page-item .page-link{
    color: black;
    font-weight: bold;
    font-size: 13px;
    font-family: 'Protest Strike', sans-serif;
}
.pay .active>.page-link, .page-link.active {
    background-color: rgba(255, 195, 18, 1);
    color: #fff !important;
    border: 1px solid rgba(255, 195, 18, 1);
    font-size: 13px;
    font-weight: bold;
    font-family: 'Protest Strike', sans-serif;
}
/* pay or play cards */
.pay{
    padding-top: 100px;
}
.pay .gold-card {
    height: 575px;
    overflow: hidden;
    text-align: center;
    border-radius: 25px;
    margin-bottom: 25px;
    padding: 15px 20px 20px 20px;
    /* background-image: url("../../assets/card_bg.png"); */
    /* background: linear-gradient(109.6deg, #242d39 11.2%, #10253c 51.2%, rgb(44 43 43 / 95%) 98.6%); */
    background: linear-gradient(180deg, #0ABEB9 -58.84%, #3A144E 100%);
}

.pay .gold-card>img {
    width: 65%;
    height: 175px;
    border-radius: 20px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 20px 40px rgba(0,0,0,.4);
}

.pay .lives-price {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pay .voucher-price{
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
    background: rgba(255, 195, 18, 1);
    border-radius: 25px 0;
    color: #000;
    float: left;
}
.pay .voucher-price .token_icon ,
.member-price .token_icon 
{
    display: inline;
    width: 20px;
    margin :0 5px ;

}
.pay .lives-number {
    text-transform: capitalize;
    text-align: left;
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    font-family: sans-serif;
    text-wrap: pretty;
}
.pay .lives-number:hover{
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
}
.pay .gold-card p {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.5;
    height: 40px !important;
    overflow: hidden;
    margin-bottom: 0;
}

.pay .btn-buy {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    background-color: rgba(255, 195, 18, 1);
    width: fit-content;
    float: right;
    border-radius: 10px;
    font-family: sans-serif;
    color: #000;
}
.pay .btn-buy svg{
    width: 20px;
    height: 20px;
}
.pay .btn-buy:hover{
    background-color: #fff;
    color: #000;
    transition: all ease-in-out 0.3s;
}

.points {
    margin: 0px 0 15px 0;
    font-weight: bold;
    font-size: 15px;
    color: #fff;
    text-align: left;
}

.points span {
    cursor: pointer;
}

.filter-icons {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.first-icon {
    width: 50px;
    height: 100%;
    background-color: rgba(28, 161, 208, 1);
    text-align: center;
    line-height: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
}

.second-icon {
    background-color: rgba(65, 66, 73, 1);
    width: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}
.pay  .active {
    background: rgba(58, 20, 78, 1) !important;
    color: #fff;
}

.pay .game-tab{
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    background-color: black;
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    color: white;
    border-radius: 100px;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.pay .vouch-details{
    background-color: #0d151e;
    margin-top: 25px;
    padding: 10px;
    border-radius: 10px;
    z-index: 9;
    position: relative;
}
.pay .pull{
    width: 40%;
    height: 50px;
    margin: 0 auto 30px auto;
}
.pay .pull .pull-1{
    width: 30px;
    height: 25px;
    margin: auto;
    background-color: #fff;
    border-radius: 50%;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.pay .pull .pull-2{
    width: 100px;
    height: 25px;
    background-color: white;
    margin: auto;
    border-radius: 60px;
}
.pay .buy{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pay .outofstock{
    background-color: red;
    color: white;
}
@media (max-width: 525px) {
    .pay .btn-buy{
        padding: 6px 10px;
        font-size: 12px;
    }
    .pay .vouch-details{
        margin-top: -25px;
    }
    .pay .voucher-price{
        font-size: 12px;
        padding: 7px 15px;
    }
    .pay  .game-tab{
        font-size: 12px;
    }
    
    .filter-icons {
        display: none;
    }

    .pay p {
        margin: 0 3px;
        font-size: 15px;
        text-align: justify;
    }

    .pay .gold-card {
        height: auto;
    }

    .pay .gold-card>img {
        width: 75%;
        height: 175px;
    }

    .pay .gold-card p {
        height: 60px !important;
        font-size: 13px;
        text-transform: capitalize;
        font-family: sans-serif;
        letter-spacing: 0;
    }

    .pay .game-tabs .game-tab {
        text-align: center;
        font-weight: bold;
        margin-bottom: 0;
    }

    .pay .game-tabs .game-tab:nth-child(6) {
        margin-right: 0px;
    }

    .pay span.lives-number{
        font-size: 16px;
    }
}