.otp {
    text-align: center;
}
.otp h1{
    margin-top: 80px;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.otp p{
    text-align: center;
}
.otp h2{
    line-height: 2;
    margin: 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
    color: rgba(77, 77, 77, 0.8);
}
.user-phone{
    color: #000;
}
.inputs{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px auto;
}
.inputs input {
    width: 70px;
    height: 80px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    padding: 0 5px;
    text-align: center;
}
.check-box{
    border: 3px solid #1877f2;
}
.otp p{
    font-size: 17px;
    display: block;
    margin: 15px;
    color: rgba(70, 70, 70, 1);
    font-weight: bold;
}
.otp p button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
}
h3 a{
    color: #000;
    text-decoration: none;
    font-size: 14px;
}
.otp-inp{
    width: 59px  !important;
    height: 68px !important;
    
}
.signUp input[type="number"]::-webkit-inner-spin-button,
.signUp input[type="number"]::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
}

@media (max-width:525px) {
    .otp h1{
        margin: 15px 0;
        font-size: 20px;
    }
    .otp h2{
        margin: 15px;
    }
}
.page-content{
/* padding-top: 2rem; */
}

h4{
    font-size:14px;
    font-weight:700;
}

h3{
    font-size:14px;
    font-weight: 700;
}
.col-3{
    padding:0px !important;
}
input{
    width:100%
}
select{width: 100%;
    height: 2.5rem;
    margin-bottom: 15px;
    border-radius: 0;
    padding: 0px;
    background: white;
    border:1px solid rgb(227, 227, 227);
    color:unset;
    font-weight: bold;
    font-size: 15px;
    text-transform: capitalize;}

    li {
        /* list-style: circle; */
      }


      .lang{
        width:5rem;
        /* height: 0.5rem; */
        margin:0px
      }

      .exit{
        background-color: white;
        /* color:red; */
        border:none;
        text-decoration: underline;
        /* margin-top: 4rem; */
      }
      .signUp-form{
        font-size: 12px;
      }
      .larger{
        font-size:13px;
        font-weight: 700;
      }
      
.terms{
    border-radius: 6px;
    background-color: #eaedf1;
    padding-top:0.5rem;
    padding-bottom: 0.5rem;
  }


  .etisalat{
    padding-top: 20rem;
  }