.htmlGame-item {
  margin-bottom: 50px;
  position: relative;
}
.htmlGame-item > img {
  width: 100%;
  height: 250px;
  border-radius: 20px;
}
.htmlGame-item .btn-join {
  width: fit-content;
  margin: auto;
  position: absolute;
  left: 15px;
  bottom: 15px;
  cursor: pointer;
  padding: 10px 50px;
  background-color: rgba(58, 20, 78, 1);
  color: white;
}
