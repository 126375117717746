
.myTournaments.overlay.cup img{
    width: 100%;
}
.myTournaments .title{
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 1px;
}
.create-tourn {
    width: 100%;
    padding: 20px 10px;
    font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.44);
    border-radius: 16px;
    outline: none;
    text-align: left;
    border: 1px dotted rgba(103, 103, 103, 1);
    background-color: transparent;
}
.tourn-btn{
    width: 100%;
    padding: 20px 10px;
    background: rgba(58, 20, 78, 1);
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: center;
    border-radius: 16px;
}
.myTournaments .cards-member{
    display: block;
}
.empty-tourn {
    font-size: 25px;
    margin-bottom: 50px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: red;
    font-family: monospace;
}
@media (max-width:525px) {
    .tourn{
        padding: 10px 5px;
    }
    .tourn-img{
        width: 100%;
    }
    .tourn-name{
        /* width: 85px; */
        font-size: 15px;
        text-align: center;
    }
    .tourn-prize{
        margin-bottom: 0;
        padding-top: 20px;
        font-size: 10px;
        background-size: contain;
        background-position: center;
    }
}