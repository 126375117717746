/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap'); */

.swipper-slider {
  /* margin-bottom: 50px; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 17px;
  overflow: hidden;
  height: 550px;
}
.swipper-slider .stars {
  display: flex;
  align-items: center;
  gap: 5px;
}
.swipper-slider .stars img {
  width: 35px;
  height: 35px;
}
.html5Games .game-tab {
  text-transform: capitalize;
}

.swipper-slider .swiper-content {
  padding: 50px 70px;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.swiper-content h1 {
  /* font-family: 'Rubik', sans-serif; */
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.swiper-content p {
  font-size: 18px;
  width: 100%;
  text-transform: capitalize;
  line-height: 1.8;
  font-weight: bold;
  color: #fff;
  text-align: start;
}

.swiper-content .btn-play {
  display: block;
  width: fit-content;
  padding: 0 15px;
  height: 52px;
  line-height: 52px;
  text-decoration: none;
  border-radius: 16px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* font-family: 'Rubik', sans-serif; */
  outline: none;
  border: none;
  color: #461c02;
  background-color: #ffc312;
  text-align: center;
}
.swiper-content .btn-play:hover {
  background-color: #ffda4a;
  color: #461c02;
  transform: scale(1.1);
  transition: all ease-in-out 0.4s;
}
.swiper-content .stars {
  margin-bottom: 10px;
}

.swiper-button-next svg,
.swiper-button-prev svg {
  color: Purple;
}

/* content tabs */
.games-heading {
  text-align: left;
  margin-top: 35px;
}

.games-heading h1 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: start;
}

.games-search {
  background-color: rgba(17, 16, 18, 1);
  padding: 10px 20px;
  border-radius: 100px;
  color: rgba(156, 164, 171, 1);
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.games-search .btn-search {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: rgba(156, 164, 171, 1);
  font-weight: bold;
}
.html5Games .active {
  color: rgba(58, 20, 78, 1);
}
.html5Games .game-tabs {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  gap: 10px;
}

.html5Games .game-tab:first-child {
  border-left: 1px solid rgba(65, 66, 73, 0.54);
  padding-left: 30px;
}

/* games item */
.html5Games .games-items {
  margin: 15px 10px;
}

.html5Games .game-item {
  text-align: center;
}

.html5Games .games-items .game-item img {
  margin-bottom: 0;
  width: 100%;
  height: 185px;
  border-radius: 20px;
}

.html5Games .games-items .game-item > span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  height: 55px;
}

.html5Games .games-items .game-item .btn-join {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
}

.html5Games .stars {
  text-align: left;
}
.category-name {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  font-family: monospace;
  color: grey;
}
.empty-games {
  font-weight: bold;
  font-size: 20px;
  color: red;
  text-transform: capitalize;
  letter-spacing: 1px;
}
/* mobile responsive */
@media (max-width: 525px) {
  .html5Games {
    padding-top: 125px !important;
  }
  .swiper-content h1 {
    font-size: 25px;
  }
  .swipper-slider .swiper-content {
    padding: 50px;
  }

  .game-tabs {
    font-size: 17px;
  }

  .html5Games .game-tab:first-child {
    border-left: 1px solid rgba(65, 66, 73, 0.54);
    padding-left: 5px;
  }

  .html5Games .game-tabs,
  .html5Games .games-items .game-item .btn-join {
    font-size: 14px;
    height: 65px;
  }

  .swiper-content .btn-play {
    width: fit-content;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    padding: 0px 15px;
  }

  .html5Games .games-items .game-item img {
    height: 125px;
  }
}
