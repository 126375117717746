.androidGames .swipper-slider {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* .androidGames .swipper-slider .swiper-content {
    padding: 90px 75px;
} */
.androidGames .active {
  color: #3a144e;
}
.androidGames .side-games {
  height: 550px;
  overflow-y: scroll;
}
.androidGames .side-game {
  padding: 10px 5px;
  border-radius: 10px;
  cursor: pointer;
}

.side-game-info {
  text-align: start;
}

.side-game .side-game-img {
  width: 110%;
  height: 60px;
  border-radius: 10px;
}

.active .side-game-info h5,
.side-game-info h5 {
  font-size: 12px;
  font-weight: bold;
}

.active .side-game-info h5 {
  color: #f5f5f5;
}

.side-game-info h5 {
  color: rgba(0, 0, 0, 1);
}

.active .side-game-info p,
.side-game-info p {
  margin: 0;
  font-size: 10px;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1.5;
  letter-spacing: 1px;
}

.active .side-game-info p {
  color: rgba(255, 255, 255, 0.8);
}

.side-game-info p {
  color: rgba(0, 0, 0, 0.55);
}

/* games section */
.heading {
  border-bottom: 1px solid black;
  margin-bottom: 25px;
  padding-left: 5px;
}

.heading h1 {
  text-align: left;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: bold;
}

.androidGames .stars {
  text-align: left;
}

.androidGames .game-img {
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
}

.androidGames .slick-slide {
  padding-right: 10px;
}
.slick-list {
  padding: 20px 3px !important;
}
.slick-next {
  background-color: none !important;
  right: -15px !important;
}
.slick-next:before,
.slick-prev:before {
  background-color: none !important;
  color: black !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.androidGames .game-item {
  background-color: #f8f9fa;
  border-radius: 15px;
  height: 300px;
  padding: 15px;
  box-shadow: 3px 3px 17px 0px rgba(0, 0, 0, 0.55);
}
.androidGames .game-item:hover {
  background: linear-gradient(180deg, #0abeb9 -58.84%, #3a144e 100%);
  color: #fff;
  border: 1px solid #0abeb9;
  transition: all ease-in-out 0.4s;
}
.androidGames .game-item:hover img {
  transform: scale(1.05);
  transition: all ease-in-out 0.4s;
  /* transform: rotate(2deg); */
}
.androidGames .game-item img {
  width: 100%;
}
.androidGames .game-item span {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 15px;
  margin: 10px 0;
  height: 55px;
  letter-spacing: 1px;
}
/* mobile responsive */
@media (max-width: 525px) {
  .androidGames {
    padding-top: 125px !important;
  }
  .androidGames .game-item {
    height: 285px;
  }
  .androidGames .game-item img {
    height: 125px;
  }
  .androidGames .game-item span {
    font-size: 14px;
  }
  .androidGames .game-img {
    border-radius: 0;
  }

  .androidGames .swipper-slider .swiper-content {
    padding: 25px;
  }

  .androidGames .swipper-slider .swiper-content .stars {
    text-align: left;
  }

  .androidGames .swipper-slider {
    margin-bottom: 50px !important;
  }

  .swiper-content h1 {
    font-size: 25px;
  }

  .swiper-content p {
    width: 100%;
    font-size: 12px !important;
    font-weight: bold;
  }

  .games-search {
    margin-bottom: 20px;
  }

  .androidGames .side-games {
    margin-top: 25px;
  }
  .side-game .side-game-img {
    width: 100%;
  }
  .slick-prev {
    left: 0 !important;
    z-index: 99;
  }
  .slick-next {
    right: 2px !important;
    z-index: 99;
  }
}
