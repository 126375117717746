.iframe-container {
    width: 100%;
    height: 100vh;
}

.iframe-container object {
    width: 100%;
    height: 100%
}

.full {
    position: absolute;
    left: 20px;
    top: 20px;
}

.full img {
    width: 50px;
    padding: 5px;
}