@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.success {
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.success h1 {
    margin-top: 35px;
    font-size: 45px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.inputs input {
    width: 70px;
    height: 80px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    padding: 0 5px;
    text-align: center;
}

.success p {
    text-align: center;
    font-size: 20px;
    display: block;
    margin: 15px;
    color: rgba(70, 70, 70, 1);
    font-weight: bold;
}

.check {
    margin: 25px auto;
    background-image: url("../../assets/forms/Path\ 42196.png");
    height: 305px;
    text-align: center;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.check img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.otp p span {
    color: black;
}

h3 a {
    color: #000;
    text-decoration: none;
    font-size: 15px;
}
@media (max-width:525px) {
    .success h1{
        margin-top: 10px;
        margin-bottom: 0;
    }
    .success p{
        margin: 0;
    }
    .check{
        height: 215px;
        margin: 10px 0 !important;
    }
}