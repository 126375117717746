.gender-select {
  height: 55px;
  border: 2px solid rgba(133, 133, 133, 1);
  background: rgba(41, 39, 32, 0.1);
  /* color: rgba(133, 133, 133, 1); */
  color: black;
  font-weight: bold;
}
.profile-img .img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
}
