.privacy {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
  text-transform: lowercase;
  padding: 2rem;
  h2 {
    font-size: 26px;
  }
  h1 {
    text-align: center;
  }
  p {
    margin: 10px 0;
  }
  ul {
    margin-left: 20px;
  }
}
