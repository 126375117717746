.game-item span{
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-align: center;
  display: block;
  height: 45px;
}
.button {
    width: 100%;
    margin: auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    background: rgba(58, 20, 78, 1);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
  }
  
  /* .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
    transform: rotate(180deg);
  } */
  
  .button:hover {
    transform: scale(1.05);
    border-color: #fff9;
    background-color: rgba(58, 20, 78, 1);
    color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .button:hover .icon {
    transform: translate(4px);
    color: #fff;
  }
  
  .button:hover::before {
    animation: shine 1.5s ease-out infinite;
  }
  
  .button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }
  
  @keyframes shine {
    0% {
      left: -100px;
    }
  
    60% {
      left: 100%;
    }
  
    to {
      left: 100%;
    }
  }
  
  @media (max-width:525px) {
    .button {
      padding: 10px 5px;
      gap: 0px;
      font-size: 10px;
    }
  }