.store .dollar {
  width: 210px;
  height: 210px;
}

.dollar img {
  width: 210px;
  height: 220px;
}

.store-tabs {
  margin: 20px auto;
  text-align: center;
  background-color: rgba(255, 200, 0, 1);
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  color: white;
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.store-tabs a,
.store-tabs span {
  width: 100%;
  border-radius: 50px;
  padding: 20px 5px;
  cursor: pointer;
  text-decoration: none;
  color: #b36c6c;
  font-size: 24px;
  font-weight: bold;
}

.store-tabs .active {
  color: #fff;
  background: rgba(58, 20, 78, 1);
  box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.store-content {
  margin-bottom: 50px;
}

/* gold cards */
.store .lives {
  display: none;
}

.cards-member {
  display: none;
}

.gold-card {
  text-align: center;
  background-color: rgba(226, 226, 226, 1);
  border-radius: 10px;
  margin: 10px 0;
  padding: 30px 0;
  margin-right: 10px;
}

.store .gold-card:last-child {
  margin-right: 0;
}

.store .gold-card > img {
  width: 85%;
  height: 225px;
  margin: auto;
  margin-bottom: 25px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
}

.store .lives-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px 10px 20px;
  gap: 5px;
  font-size: 18px;
}

span.lives-number {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: capitalize;
  font-family: cursive;
}

span.lives-price {
  background-color: rgba(255, 195, 18, 1);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
}

.btn-buy {
  background: rgba(58, 20, 78, 1);
  color: white;
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-decoration: none;
}

.store .buy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 35px;
}

.add-to-cart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
}

.add-to-cart img {
  width: 43px;
  height: 43px;
}

.store .show-cards {
  display: block;
  margin: 50px 0;
}

/* gold cards */

.member-title {
  text-align: left !important;
  margin-bottom: 10px;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}
.member-description {
  font-size: 15px !important;
  text-align: left !important;
  line-height: 1.8 !important;
  margin-bottom: 10px;
  font-weight: bold !important;
}
.member-price {
  text-align: left !important;
  background-color: rgba(255, 195, 18, 1);
  margin-bottom: 0;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 17px !important;
  font-weight: bold !important;
  letter-spacing: 1px;
}
@media (max-width: 525px) {
  .store .show-cards {
    margin: 0 !important;
  }
  .store-tabs {
    margin: 0;
    margin-bottom: 15px;
  }
  .store-tabs a {
    width: 100%;
    border-radius: 50px;
    padding: 20px 5px;
    cursor: pointer;
    font-size: 12px;
  }

  .store .lives-price {
    margin: 0px 10px;
    gap: 5px;
    font-size: 13px;
  }

  span.lives-number {
    font-size: 16px;
  }

  .store .buy {
    margin: 25px 10px 0 10px;
  }
}
